import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { ToastController } from '@ionic/angular';

import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import * as firebase from 'firebase';

import { Observable } from 'rxjs';

import { UserService } from '../user/user.service';
import { Address } from 'src/app/models/address.model';
import { Product } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private fireAuth: AngularFireAuth,
    private userService: UserService,
    private toastController: ToastController
  ) {
  }

  public createAccount(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            resolve(res.user);
            res.user.sendEmailVerification();
          }
        })
        .catch(err => {
          reject(`creation failed ${err}`);
        });
    });
  }

  public login(email: string, password: string) {
    return new Promise((resolve, rejected) => {
      this.fireAuth.auth.signInWithEmailAndPassword(email, password)
        .then(res => {
          resolve(res);
        })
        .catch(err => rejected(err));
    });
  }

  public logout() {
    return new Promise((resolve, rejected) => {
      this.fireAuth.auth.signOut()
        .then(res => {
          console.log(res);
        })
        .catch(err => rejected(err));
    });
  }

  public forgotPassoword(email: string) {
    this.fireAuth.auth.sendPasswordResetEmail(email).then(async () => {
      const toast = await this.toastController.create({
        header: 'El Salón Virtual.',
        message: "Correo de recuperación de contraseña enviado.",
        position: 'bottom',
        buttons: [
          {
            text: 'aceptar',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      toast.present();
    }).catch(async (err) => {
      if (err.code == "auth/invalid-email") {
        const toast = await this.toastController.create({
          header: 'El Salón Virtual.',
          message: "Ingrese un email con formato valido",
          position: 'bottom',
          buttons: [
            {
              text: 'aceptar',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ]
        });
        toast.present();
      } else if (err.code == "auth/user-not-found") {
        const toast = await this.toastController.create({
          header: 'Mi Salón Virtual.',
          message: "No se encontró ninguna cuenta con el email ingresado",
          position: 'bottom',
          buttons: [
            {
              text: 'aceptar',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ]
        });
        toast.present();

      } else {
        const toast = await this.toastController.create({
          header: 'El Salón Virtual.',
          message: "error desconocido",
          position: 'bottom',
          buttons: [
            {
              text: 'aceptar',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ]
        });
        toast.present();
      }
    }
    );
  }

  public loginWithFacebook(accessToken) {
    const credential = firebase.auth.FacebookAuthProvider.credential(accessToken);
    return this.fireAuth.auth.signInWithCredential(credential);
  }

  public fbLogin(): Promise<any> {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.fireAuth.auth.signInWithPopup(provider);
  }

  public loginWithGoogle(accessToken, accessSecret) {
    const credential = firebase.auth.GoogleAuthProvider.credential(accessToken, accessSecret);
    return this.fireAuth.auth.signInWithCredential(credential);
  }

  public googleLogin(): Promise<any> {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.fireAuth.auth.signInWithPopup(provider);
  }
}
