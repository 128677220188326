import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.page.html',
  styleUrls: ['./add-email.page.scss'],
})
export class AddEmailPage implements OnInit {

  emailFrm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    public userService: UserService,
    private toastController: ToastController
  ) {
    this.emailFrm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  doAddEmail() {
    if (this.emailFrm.valid) {
      this.userService.updateEmail(this.userService.user.firebaseToken, this.emailFrm.value.email).subscribe(
        res => {
          if(res['success']){
            this.presentToast(res['message']);
            this.userService.user.email = this.emailFrm.value.email;
          }
        }
      );
      this.modalController.dismiss({
        'dismissed': true
      });
    } else {
      this.emailFrm.markAsTouched();
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      header: 'El Salón Virtual.',
      message: message,
      position: 'bottom',
      duration: 2000
    });
    toast.present();
  }

}
